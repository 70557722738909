import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import DashBoardSelect from 'components/Dropdown/dashboardSelect';
import { Menu, MenuDivider, MenuItem } from '@szhsin/react-menu';
import RoleSelect from 'components/Dropdown/RoleSelect';

// Images
import logo from 'images/logo-bird.svg';
import profileIcon from 'images/profile.svg';
import historyIcon from 'images/history.svg';
import chatIcon from 'images/chat-alt.svg';
import uploadIcon from 'images/upload-icon.svg';
import dashboardIcon from 'images/dashboard.svg';
import alertIcon from 'images/alert.svg';
import collaborativeIdeaIcon from "images/collabrate-trio-circle-icon-2.svg";
import newChatIcon from "images/pen-new-square-icon.svg";
import { resetChats } from 'slices/chat/reducer';
import { useDispatch } from 'react-redux';
import { useAuth } from 'context/AuthContext';
import clsx from 'clsx';
import { getAvatarBGColor } from 'utils/helper';
import { setSelectedDashboard } from 'slices/dashboard/reducer';

const HeaderAlt = ({ hasDashboardFilter = false }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { logout, user } = useAuth();

    const isActive = (path) => {
        if (path === "/analyze-doc" && location.pathname.startsWith("/analyze-doc/")) {
            return "bg-[#7357eb]";
        }
        return location.pathname === path ? 'bg-[#7357eb]' : ''
    };

    const startNewChat = () => {
        dispatch(resetChats([]));
        navigate('/chat')
    }

    const goToDashboard = () => {

        if (location.pathname !== "/dashboard") {
            const allDashboards = {
                label: "All dashboards",
                value: null,
                id: null,
            }
            dispatch(setSelectedDashboard(allDashboards));

            navigate('/dashboard');
        }
    }

    return (
        <header className="flex items-center justify-between p-4 header-border-margin">
            <div className="flex items-center">
                <img src={logo} alt="Logo" className="h-9 mr-4" />
                <nav className="before:content-[''] before:border-l before:h-6 before:pr-3 before:border-left border-header-custom-before flex items-center space-x-4">

                    {
                        location.pathname.startsWith("/chat") && (
                            <button
                                title="start new chat"
                                onClick={startNewChat}
                                className={`bg-[#69738a] hover:bg-[#7357eb] text-white py-2 px-2.5 rounded-xl border-header-custom flex items-center transition-transform duration-200 ease-in-out transform hover:scale-105`}
                            >
                                <img src={newChatIcon} alt="Start new chat" className="h-5 w-5" />
                            </button>
                        )
                    }

                    <button
                        onClick={() => navigate('/chat')}
                        className={`${isActive('/chat')} hover:bg-[#7357eb] text-sm text-white py-2 px-2.5 rounded-xl border-header-custom flex items-center transition-transform duration-200 ease-in-out transform hover:scale-105`}
                    >
                        <img src={chatIcon} alt="Chat Icon" className="h-4 w-4 mr-2" />
                        Chat
                    </button>
                    {/* <button
						onClick={() => navigate('/analyze-doc')}
						className={`${isActive('/analyze-doc')} hover:bg-[#7357eb] text-sm text-white py-2 px-2.5 rounded-xl border-header-custom flex items-center transition-transform duration-200 ease-in-out transform hover:scale-105`}
					>
						<img src={uploadIcon} alt="Upload File Icon" className="h-4 w-4 mr-2" />
						Analyze a Doc
					</button> */}
                    <button
                        onClick={goToDashboard}
                        className={`${isActive('/dashboard')} hover:bg-[#7357eb] text-sm text-white py-2 px-2.5 rounded-xl border-header-custom flex items-center transition-transform duration-200 ease-in-out transform hover:scale-105`}
                    >
                        <img src={dashboardIcon} alt="Dashboard Icon" className="h-4 w-4 mr-2" />
                        Dashboard
                    </button>
                    <button
                        onClick={() => navigate('/collaborative-forum')}
                        className={`${isActive('/collaborative-forum')} hover:bg-[#7357eb] text-sm text-white py-2 px-2.5 rounded-xl border-header-custom flex items-center transition-transform duration-200 ease-in-out transform hover:scale-105`}
                    >
                        <img src={collaborativeIdeaIcon} alt="Threads" className="h-[14px] w-[14px] mr-2 opacity-80" />
                        Threads
                    </button>
                    <button
                        onClick={() => navigate('/alerts')}
                        className={`${isActive('/alerts')} hover:bg-[#7357eb] text-sm text-white py-2 px-2.5 rounded-xl border-header-custom flex items-center transition-transform duration-200 ease-in-out transform hover:scale-105`}
                    >
                        <img src={alertIcon} alt="Alerts Icon" className="h-4 w-4 mr-2" />
                        Alerts
                    </button>

                    <RoleSelect />
                </nav>
            </div>
            <div className="flex items-center gap-x-4">

                {/* DashBoard Filter */}
                {
                    hasDashboardFilter && <DashBoardSelect />
                }

                {/* Add Chat Icon */}
                {/* <button className="bg-[#F283B6] text-white py-1.5 px-2 border-header-custom rounded-xl transition-transform duration-200 ease-in-out transform hover:scale-105">
          <img src={plusIcon} alt="Profile" className="h-8 w-8 rounded-full p-1" />
        </button> */}

                {/* History Icon */}
                <button onClick={() => navigate('/history')} className={`${isActive('/history')} text-white py-1.5 px-2 rounded-full border-header-custom rounded-xl transition-transform duration-200 ease-in-out transform hover:scale-105`}>
                    <img src={historyIcon} alt="Profile" className="h-8 w-8 rounded-full p-1" />
                </button>

                {/* Notification Icon */}
                {/* <div className="relative">
          <button className="relative text-black transition-transform duration-200 ease-in-out transform hover:scale-105">
            <span className="absolute top-0 right-0 bg-[#F283B6] rounded-full h-4 w-4 flex items-center justify-center text-xs">0</span>
            <img src={notificationIcon} alt="Profile" className="h-8 w-8 rounded-full" />
          </button>
        </div> */}

                {/* Profile Icon */}
                <Menu
                    arrow={true}
                    position={"anchor"}
                    gap={12}
                    align={"end"}
                    menuClassName={`z-10 min-w-[10rem] bg-primary p-2 rounded shadow-primary-1`}
                    menuButton={
                        <button title={`Profile`} className={clsx('h-10 w-10 flex items-center justify-center rounded-full transition-transform duration-200 ease-in-out transform hover:scale-105', getAvatarBGColor(user?.id || "User"))}>
                            {
                                user?.profilePicUrl ? (
                                    <img src={user.profilePicUrl} alt="Profile" className="cursor-pointer h-10 w-10 rounded-full" />
                                ) : (
                                    <span className="text-black">{user?.firstName?.[0]?.toUpperCase() || "U"}</span>
                                )
                            }
                        </button>
                    }
                >

                    <MenuItem
                        onClick={() => navigate('/manage-credentials')}
                        className={`cursor-pointer m-0 p-2 text-white text-sm text-nowrap hover:bg-primary-3 hover:font-medium`}
                    >
                        Manage Credentials
                    </MenuItem>

                    <MenuItem
                        onClick={() => navigate('/change-password')}
                        className={`cursor-pointer m-0 p-2 text-white text-sm text-nowrap hover:bg-primary-3 hover:font-medium`}
                    >
                        Change Password
                    </MenuItem>

                    <MenuDivider />

                    <MenuItem
                        onClick={logout}
                        className={`cursor-pointer m-0 p-2 text-white text-sm text-nowrap hover:bg-primary-3 hover:font-medium`}
                    >
                        Logout
                    </MenuItem>
                </Menu>

            </div>
        </header>
    );
};

export default HeaderAlt;