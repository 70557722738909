import * as yup from "yup";

export const AddCollabrationSchema = yup.object().shape({
    query_id: yup.number().required(),
    user_id: yup.number().required(),
    question: yup.string().trim().required("Please ask your question"),
    shared_parameters: yup.array().of(yup.string()),
    shared_ids: yup.array().of(yup.string())
        .test({
            message: "Please select at least one user",
            test: arr => arr.length >= 1
        }),
});