import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from 'styles/home.module.css';
import dialogueStyles from 'styles/dialogue.module.css';
import SpeechRecognitionComponent from 'components/SpeechRecognitionComponent';
import SuggestedQuestions from 'components/SuggestedQuestions';
import ThoughtProvokingQuestions from 'components/ThoughtProvokingQuestions';
import DOMPurify from 'dompurify';

import config from 'components/config';
import { useDispatch, useSelector } from 'react-redux';
import { getChatHistoryListByDateThunk } from 'slices/chat/thunk';

// Images
import volume from 'images/dialogue/speaker.svg';
import mute from 'images/dialogue/non-speaker.svg';
import volumeDisabled from 'images/dialogue/speaker-disable.png';
import storyIcon from 'images/dialogue/storymetrix chat bird.svg';
import loadingGif from 'images/loader2.webp';
import { getForecast } from 'api/chat';
import ForecastModal from 'components/Modal/ForecastModal';
import HighchartsComponentAlt from 'components/HighchartsComponentAlt';
import Downvote from 'components/Downvote';
import { createBotMessage } from 'utils/chat';
import CreateCollabrationModal from 'components/Collabration/CreateCollabrationModal';
import { notifyError } from "utils/customToasts";
import { setHistoryChats, updateHistoryChats, addNewHistoryChat } from 'slices/chat/reducer';
import TableView from 'components/Chat/TableView';
import ChatActions from 'components/Chat/ChatActions';
import ChatHeader from 'components/Chat/ChatHeader';
import { useAuth } from 'context/AuthContext';
import { getRecommendedQuestion } from 'utils/helper';
import { logFailedMessage } from 'api/feedback/index';


const ChatHistory = () => {

    const { user } = useAuth();
    // User Role
    const { selectedRole } = useSelector(state => state.AppReducer);
    const selectedRoleOption = selectedRole?.label;

    const [newMessage, setNewMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingForecast, setIsLoadingForecast] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false); // <false | IndexOfArray>
    const [selectedDownvoteFor, setSelectedDownvoteFor] = useState(null); // <false | IndexArray>
    const [isVolumeEnabled, setIsVolumeEnabled] = useState(true);
    const [audioPath, setAudioPath] = useState('');
    const [defaultSuggestions, setDefaultSuggestions] = useState([]);
    const [thoughtProvokingQuestion, setThoughtProvokingQuestion] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(true);
    const [showQuestions, setShowQuestions] = useState(true);
    const [clickCounts, setClickCounts] = useState({});
    const chatEndRef = useRef(null);
    const audioRef = useRef(null);
    const [loadingText, setLoadingText] = useState('Please Wait');
    const loadingTexts = ['Querying...', 'Fetching...', 'Analyzing...', 'Generating...'];
    const location = useLocation();
    const apiUrl = config.apiUrl;

    // URL Params
    const params = new URLSearchParams(location.search);
    const queryDate = params.get('date') || null;
    const queryId = params.get('query_id') || null;

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const chatsData = useSelector(state => state.ChatReducer);
    // const chats = chatsData.chats;

    const historyChats = useMemo(() => {
        if (queryDate && chatsData.hasOwnProperty(queryDate)) {
            return chatsData[queryDate];
        }
        return [];
    }, [queryDate, chatsData?.[queryDate]?.length]);

    const getValueFlagFromURL = () => {
        return params.get('voice') || 'false';
    };

    const createUserMessage = (message) => ({
        sender: "user",
        content: message?.question,
        sqlQuery: "",
        firstMessage: "no",
    });

    const loadOldData = (oldMessageList) => {
        const userMessages = oldMessageList?.map((message) => {
            const userMessage = createUserMessage(message);
            const botMessage = createBotMessage(message);
            return [userMessage, botMessage]
        }).flat();

        dispatch(setHistoryChats({ date: queryDate, data: userMessages }));
    }

    const fetchChatsDataByDate = async () => {
        const body = {
            userId: 1,
            date: queryDate
        }
        const res = await dispatch(getChatHistoryListByDateThunk(body));
        const reversedData = res.payload.result.toSorted((a, b) => a.query_id - b.query_id);
        loadOldData(reversedData);
    }

    useEffect(() => {
        if (!queryDate || !queryId) {
            navigate("/chat");
            return;
        }

        if (!chatsData.hasOwnProperty(queryDate)) {
            fetchChatsDataByDate()
        }
    }, [queryDate, queryId]);

    // Update Suggested Questions
    useEffect(() => {
        const suggestions = getRecommendedQuestion(selectedRoleOption);
        setDefaultSuggestions(suggestions);
    }, [selectedRoleOption]);

    useEffect(() => {
        if (!isLoading) return;

        const changeLoadingText = async () => {
            for (let i = 0; i < loadingTexts.length; i++) {
                await new Promise(resolve => setTimeout(resolve, 2000));
                setLoadingText(loadingTexts[i]);
            }
        };

        setLoadingText('Please Wait'); // Reset to initial state
        changeLoadingText();
    }, [isLoading]);

    useEffect(() => {
        const valueFlag = getValueFlagFromURL();
        setIsVolumeEnabled(valueFlag === 'true');
    }, [location.search]);

    const fetchBotResponse = async (message) => {
        try {
            const company = "codewave";
            const chartType = "";
            const valueFlag = getValueFlagFromURL();
            const messageWithPersona = message;
            const url = apiUrl + `/chat/`;
            const params = {
                question: messageWithPersona,
                graph_prompt: chartType,
                company_name: company,
                value_flag: valueFlag,
                user_persona: selectedRoleOption,
                user_id: user?._id
            }
            const response = await axios.get(url, { params });

            if (!response.status) {
                throw new Error(response.data.answer);
            }

            return response.data.data || response.data;
        } catch (error) {
            const errorMessage = error?.response?.data?.detail || error?.response?.data?.message || error?.response?.data?.answer || error?.message;

            const logPayload = {
                user_id: user?._id,
                question: message,
                error_message: errorMessage
            }
            logFailedMessage(logPayload);

            console.error('Error fetching bot response:', error);
            return { answer: 'Error: Unable to get response from bot.', suggestions: [], thoughtprovoking: '' };
        }
    };
    //   try {
    //     const response = await fetch('/line.json');
    //     const jsonData = await response.json();

    //     // Assuming your JSON structure has a specific way to get the response, modify accordingly
    //     // const botResponse = jsonData.find(item => item.question === message);

    //     if (jsonData) {
    //       return jsonData.data;
    //     } else {
    //       return { answer: 'No matching response found.', suggestions: [], thoughtprovoking: '' };
    //     }
    //   } catch (error) {
    //     console.error('Error fetching bot response:', error);
    //     return { answer: 'Error: Unable to get response from bot.', suggestions: [], thoughtprovoking: '' };
    //   }
    // };

    const sendMessage = async (message) => {
        if (message.trim() === '') return;

        const userMessage = { sender: 'user', content: message, sqlQuery: '', firstMessage: 'no' };

        dispatch(addNewHistoryChat({ date: queryDate, data: userMessage }));
        setNewMessage('');
        setIsLoading(true);

        const botResponse1 = await fetchBotResponse(message);
        const botResponse = botResponse1?.data || botResponse1;
        const botMessage = {
            sender: 'bot',
            query_id: botResponse.query_id,
            content: botResponse?.summary || botResponse?.answer,
            copyContent: "summary - " + botResponse1?.summary_actions?.summary_text + " actions - " + botResponse1?.summary_actions?.actions_text,
            actions: botResponse?.actions,
            sqlQuery: botResponse?.sql || '',
            firstMessage: 'no'
        };

        if (botResponse?.table?.length >= 1) {
            Object.assign(botMessage, {
                graphTitle: botResponse?.graph_head,
                graphType: botResponse?.graph_type,
                graph: botResponse?.table,
                graphDataToPlot: botResponse?.highchart_code,
                viewType: "chart"
            });
        }

        dispatch(addNewHistoryChat({ date: queryDate, data: botMessage }));
        setAudioPath(botResponse?.audio_path); // set but not in used
        setDefaultSuggestions(botResponse?.related_questions || defaultSuggestions);
        setThoughtProvokingQuestion(botResponse?.actions || '');
        setIsLoading(false);
        setShowSuggestions(true);
        setShowQuestions(true);

        if (isVolumeEnabled && botResponse?.audio_path) {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
            audioRef.current = new Audio(botResponse.audio_path);
            audioRef.current.play();
        }
    };

    const handleTranscript = (transcript) => {
        setNewMessage(transcript);
        setTimeout(() => {
            sendMessage(transcript);
            setNewMessage('');
        }, 1000);
    };

    const handleSuggestionClick = (suggestion) => {
        if (isLoading == false) {
            setNewMessage(suggestion);
            sendMessage(suggestion);
        }
    };

    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [historyChats?.length, isLoading]);

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter' && isLoading == false) {
            sendMessage(newMessage);
        }
    };

    const toggleVolume = () => {
        setIsVolumeEnabled((prev) => {
            const newVolumeState = !prev;
            if (audioRef.current) {
                if (newVolumeState) {
                    audioRef.current.play();
                } else {
                    audioRef.current.pause();
                }
            }
            return newVolumeState;
        });
    };

    const handleStoryIconClick = (index) => {
        setClickCounts((prevClickCounts) => {
            const newClickCounts = { ...prevClickCounts };

            if (newClickCounts[index] === 2) {
                // If the SQL query is already visible, hide it on click
                delete newClickCounts[index];
            } else {
                // If not visible, display the query
                newClickCounts[index] = 2;
            }

            return newClickCounts;
        });
    };

    const fetchForecastData = async (payload) => {
        try {
            const response = await getForecast(payload);
            if (!response?.status) {
                throw new Error(response?.message || "Can't generate forecast for this question");
            }

            if (response?.data?.answer) {
                throw new Error(response?.data?.answer);
            }

            return response.data;
        } catch (error) {
            console.error('Error fetching forecast data:', error);
            throw new Error(error?.message || "Error fetching forecast data")
        }
    }

    const handleForeCastClick = async (selectedForecastOption, messageData, messageIndex) => {
        try {
            setSelectedIndex(messageIndex);
            setIsLoadingForecast(true);
            if (messageData.hasOwnProperty("forecastData")) {

                if (!messageData?.forecastData?.forecast_result?.plot_data?.[selectedForecastOption?.column]?.[selectedForecastOption?.forecastType]) {
                    throw new Error(`${selectedForecastOption?.forecastType} option is not available for ${selectedForecastOption?.column} field`);
                }

                const updateData = {
                    date: queryDate,
                    index: messageIndex,
                    data: {
                        forecastOption: selectedForecastOption
                    }
                }
                dispatch(updateHistoryChats(updateData));
                setIsLoadingForecast(false);
                return
            }

            const dataFrameString = JSON.stringify(messageData.graph).replace(/\\/g, '\\\\').replace(/"/g, '\\"');
            const forcastPayload = {
                question: historyChats[messageIndex - 1]?.content,
                dataframe: `"${dataFrameString}"`
            }

            const forecastData = await fetchForecastData(forcastPayload);

            if (!forecastData) {
                setIsLoadingForecast(false);
                throw new Error(`Forecast data not found`);
            }

            if (!forecastData?.forecast_result?.plot_data?.[selectedForecastOption?.column]?.[selectedForecastOption?.forecastType]) {
                const updateData = {
                    date: queryDate,
                    index: messageIndex,
                    data: {
                        forecastData: forecastData
                    }
                }
                dispatch(updateHistoryChats(updateData));
                setIsLoadingForecast(false);
                throw new Error(`${selectedForecastOption?.forecastType} option is not available for ${selectedForecastOption?.column} field`);
            }

            const updateData = {
                date: queryDate,
                index: messageIndex,
                data: {
                    forecastData: forecastData,
                    forecastOption: selectedForecastOption
                }
            }
            dispatch(updateHistoryChats(updateData));

            setIsLoadingForecast(false);
        } catch (err) {
            setIsLoadingForecast(false);
            notifyError(err?.message || "Error fetching forecast data", {
                position: "top-right"
            })
        }
    }

    const handleDownvoteClick = (messageIndex) => {
        const queryId = historyChats?.[messageIndex]?.query_id;
        setSelectedDownvoteFor(queryId);
    }

    return (
        <div className={dialogueStyles.chatContainerWrapper}>
            <div className={dialogueStyles.chatWrapper}>
                {
                    historyChats?.map((message, index) => (
                        <div key={index} className={message.sender === 'bot' ? styles.chatMessageBotContainer : styles.chatMessageUserContainer}>
                            <div className={message.sender === 'bot' ? styles.chatMessageContainerDivBot : styles.chatMessageContainerDivUser}>

                                {/* Chat Header */}
                                <ChatHeader
                                    message={message}
                                    index={index}
                                    regenerate={() => sendMessage(historyChats[index - 1]?.content)}
                                    downvote={() => handleDownvoteClick(index)}
                                    handleStoryIconClick={() => handleStoryIconClick(index)}
                                />

                                <div className={`${styles.messageSummary}`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.content) }} />

                                {
                                    clickCounts[index] === 2 && message.sender === 'bot' && message.sqlQuery && (
                                        <div style={{ marginTop: '10px', fontStyle: 'italic', color: '#7357ea' }}>
                                            SQL Query: {message.sqlQuery}
                                        </div>
                                    )
                                }

                                {
                                    message?.graph && (
                                        <div style={{ position: 'relative', top: '10px', marginBottom: '10px', border: '1px solid #BEEF9E', borderRadius: '15px' }}>
                                            {
                                                message?.viewType === "chart" && (
                                                    <HighchartsComponentAlt
                                                        key={`${message?.graphTitle}-${index}`}
                                                        chartTitle={message.graphTitle}
                                                        data={message.graph}
                                                        dataToPlot={message.graphDataToPlot}
                                                    />
                                                )
                                            }

                                            {
                                                message?.viewType === "table" && (
                                                    <TableView
                                                        data={message.graph}
                                                    />
                                                )
                                            }

                                            {
                                                (message.sender === 'bot' && message.firstMessage === 'no' && message?.graph) && (
                                                    <ChatActions message={message} index={index} forecastClick={(data) => handleForeCastClick(data, message, index)} />
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    ))
                }

                {isLoading && (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <div className={styles.chatMessageContainerLoader}>
                            <img src={storyIcon} alt="bot" style={{ height: '24px', marginRight: '10px' }} />
                            <div className={styles.chatMessageContainerLoaderWaitDiv}>
                                <div>
                                    <img src={loadingGif} alt="loading" style={{ height: '24px' }} />
                                </div>
                                <span className={styles.chatMessageContainerLoaderWaitSpan}>{loadingText}</span>
                            </div>
                        </div>
                    </div>
                )}
                <div ref={chatEndRef}></div>
                <div className={styles.chatMessageInputWrapper}>
                    <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={handleEnterKeyPress}
                        placeholder="Ask anything, What can i do for you?"
                        className={dialogueStyles.chatMessageInputWrapper}
                    />
                    <SpeechRecognitionComponent onTranscript={handleTranscript} />
                    {getValueFlagFromURL() === 'true' && (
                        <img
                            style={{ height: '25px', cursor: 'pointer', borderLeft: '1px solid #fff', padding: '0px 10px' }}
                            className={styles.homeFill2Icon}
                            src={isVolumeEnabled ? volume : mute}
                            onClick={toggleVolume}
                        />
                    )}
                    {getValueFlagFromURL() === 'false' && (
                        <img
                            style={{ height: '25px', cursor: 'pointer', borderLeft: '1px solid #fff', padding: '0px 10px', cursor: 'not-allowed' }}
                            className={styles.homeFill2Icon}
                            title="disabled volume"
                            alt="disabled volume"
                            src={volumeDisabled}
                        />
                    )}
                </div>
            </div>
            <div className={dialogueStyles.rightSidebar}>
                {showSuggestions && <SuggestedQuestions suggestions={defaultSuggestions} onClickSuggestion={handleSuggestionClick} />}
                {showQuestions && <ThoughtProvokingQuestions question={thoughtProvokingQuestion} />}
            </div>

            <ForecastModal
                loading={isLoadingForecast}
                open={selectedIndex !== false}
                onClose={() => setSelectedIndex(false)}
                onChange={(data, message) => handleForeCastClick(data, message, selectedIndex)}
                messageData={selectedIndex >= 0 ? historyChats[selectedIndex] : null}
            />

            <Downvote
                open={selectedDownvoteFor !== null}
                queryId={selectedDownvoteFor}
                onClose={() => setSelectedDownvoteFor(null)}
            />

            <CreateCollabrationModal />
        </div>
    );
};

export default ChatHistory;